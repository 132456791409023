<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 v-if="errState === false" class="text-lg leading-6 font-medium text-gray-900">
        Connecting to Notion...
      </h3>
      <h3 v-else class="text-lg leading-6 font-medium text-red-700">
        <div class="my-4">
          Fatal error connecting to Notion.
        </div>
        <div class="my-4">
          This is a bummer, but only temporary.
        </div>
        <div class="my-4">
          We will investigate and email you personally!
        </div>
      </h3>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'

export default {
  data () {
    return {
      errState: false,
    }
  },
  async mounted() {
    Sentry.setContext("callback", {
      address: this.$route.query.state,
    })
    if ('code' in this.$route.query) {
      var data = null
      try {
        data = await this.axios.post(process.env.VUE_APP_API_URL + 'connect', { code: this.$route.query.code, address: this.$route.query.state }, { headers: {'Authorization': 'Bearer ' + this.$auth.token.value }})
      } catch (err) {
        Sentry.captureException(new Error(`Notioncallback connect POST: ${err}`))
        this.errState = true
        return
      }
      if (data.error) {
        Sentry.captureException(new Error(`Notioncallback connect POST result: ${data.error}`))
        this.errState = true
        return
      }
      this.$router.push({ path: '/connect/' + this.$route.query.state })
    } else {
      this.$router.push({ path: '/' })
    }
  }
}
</script> 
